import { SpringValue } from '@react-spring/web'
import { FormikProps } from 'formik'

export const companyNameQuestion = "... and your company's website?"
export const nameQuestion = "What's your name?"
export const jobTitleQuestion = 'What best describes your role?'
export const teamSizeQuestion = 'How big is your team?'
export const salesStatusQuestion = 'Which of these best describes your company?'
export const productTypeQuestion = 'What kind of products do you plan to sell with Kickflip?'
export const launchQuestion = 'When do you plan to launch your project?'

export type SurveyValues = {
  name: string
  companyName?: string
  domain?: string
  teamSize?: string
  jobTitle?: string
  salesStatus?: string
  launch?: string
  productType?: string
  otherProductType?: string
}

export type SurveySubmitValues = {
  name: string
  companyName: string
  domain: string
  teamSize: string
  jobTitle: string
  salesStatus: string
  launch: string
  productType: string
  otherProductType: string
}

export type FormikSurvey = FormikProps<SurveyValues>

export interface StepProps {
  style: Omit<React.CSSProperties, 'opacity' | 'transform'> & {
    opacity: SpringValue<number>
    transform: SpringValue<string>
  }
  onPrevious: () => void
  onNext: () => void
  goToLast: () => void
  formikProps: FormikSurvey
  isAnimating: boolean
}

export const rawSurveyMapping: { question: string; key: keyof Omit<SurveyValues, 'otherProductType'> }[] = [
  {
    question: companyNameQuestion,
    key: 'companyName',
  },
  {
    question: nameQuestion,
    key: 'name',
  },
  {
    question: salesStatusQuestion,
    key: 'salesStatus',
  },
  {
    question: teamSizeQuestion,
    key: 'teamSize',
  },
  {
    question: jobTitleQuestion,
    key: 'jobTitle',
  },
  {
    question: launchQuestion,
    key: 'launch',
  },
  {
    question: productTypeQuestion,
    key: 'productType',
  },
]
