import classNames from 'classnames'
import React from 'react'

import CardTabs from 'common/components/tabs/CardTabs'
import Input from 'common/components/inputs/Input'

type Option = {
  value?: string
  label: string
  isOpen?: boolean
  fieldValue?: string
}

export type RadioGroup<T extends Option> = {
  name: string
  options: T[]
  onChange: (value: T['value']) => void
  onChangeFieldValue?: (field: T['value'], value: string) => void
  value?: T['value']
}

const RadioGroup = <T extends Option>({ name, options, onChange, onChangeFieldValue, value }: RadioGroup<T>) => {
  return (
    <div>
      <CardTabs role="radiogroup" aria-label={name}>
        {options.map(option =>
          !option.isOpen ? (
            <CardTabs.Tab
              key={option.value}
              tabIndex={0}
              role="radio"
              className="aria-selected:font-medium"
              isSelected={value === option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </CardTabs.Tab>
          ) : (
            <CardTabs.Tab
              role="radio"
              key={option.value}
              isSelected={value === option.value}
              onClick={() => onChange(option.value)}
              className={classNames('aria-selected:font-medium', { '!p-0': value === option.value })}
              tabIndex={0}
            >
              {value === option.value ? (
                <Input
                  placeholder="Enter your answer"
                  value={option.fieldValue}
                  onChange={e => onChangeFieldValue?.(option.value, e.target.value)}
                  className="!border-none !h-[41.5px] !py-3 !px-2 !bg-transparent text-primary-500"
                  inputClassName="bg-transparent placeholder:text-black/40  !font-medium"
                  onBlur={e => {
                    if (e.target.value === '') onChange('')
                  }}
                  autoFocus
                />
              ) : (
                option.fieldValue || option.label
              )}
            </CardTabs.Tab>
          )
        )}
      </CardTabs>
    </div>
  )
}

export default RadioGroup
