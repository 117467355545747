import { SpringValue, useSpringRef, useTransition } from '@react-spring/web'
import React, { useEffect, useState } from 'react'

import usePrevious from 'common/hooks/usePrevious'

interface StepProps {
  style: Omit<React.CSSProperties, 'opacity' | 'transform'> & {
    opacity: SpringValue<number>
    transform: SpringValue<string>
  }
  onPrevious: () => void
  onNext: () => void
  goToLast: () => void
  isAnimating: boolean
}

export type StepperProps<T extends Record<string, any>> = {
  currentStep: number
  Steps: React.ComponentType<T & StepProps>[]
  setStep: (stepIndex: number) => void
  stepProps: T
}

const Stepper = <T extends Record<string, any>>({ currentStep, Steps, setStep, stepProps }: StepperProps<T>) => {
  const previousStep = usePrevious(currentStep)
  const transRef = useSpringRef()
  const [isAnimating, setIsAnimating] = useState(false)
  const transitions = useTransition(currentStep, {
    ref: transRef,
    keys: null,
    from: () => ({
      opacity: 0,
      transform: previousStep > currentStep ? 'translate3d(-130%,-50%,0)' : 'translate3d(150%,-50%,0)',
    }),
    enter: { opacity: 1, transform: 'translate3d(0%,-50%,0)' },
    leave: () => ({
      opacity: 0,
      transform: previousStep > currentStep ? 'translate3d(150%,-50%,0)' : 'translate3d(-130%,-50%,0)',
    }),
    onStart: () => setIsAnimating(true),
    onRest: () => setIsAnimating(false),
  })

  useEffect(() => {
    transRef.start()
  }, [currentStep])

  return transitions((style, i) => {
    const Step = Steps[i]

    return (
      <Step
        key={`step-${i}`}
        style={{
          ...style,
          position: 'absolute' as React.CSSProperties['position'],
          top: '50%',
          width: '100%',
        }}
        onNext={() => setStep(currentStep + 1)}
        goToLast={() => setStep(Steps.length - 1)}
        onPrevious={() => setStep(currentStep - 1)}
        isAnimating={isAnimating}
        {...stepProps}
      />
    )
  })
}

export default Stepper
