import React from 'react'

import SkipAllButton from './../fullPageSurvey/SkipAllButton'
import SkipButton from './../fullPageSurvey/SkipButton'
import { FormikSurvey, SurveyValues } from './types'

const RegisterSurveySkipButtons = ({
  field,
  onSkip,
  onSkipAll,
  formikProps,
}: {
  field: keyof SurveyValues
  onSkip: () => void
  onSkipAll: () => void
  formikProps: FormikSurvey
}) => (
  <div className="ml-auto flex space-x-2">
    <SkipAllButton
      onClick={() => {
        formikProps.setValues({
          ...{
            companyName: '',
            domain: '',
            teamSize: '',
            jobTitle: '',
            salesStatus: '',
            launch: '',
            productType: '',
            otherProductType: '',
          },
          name: formikProps.values.name,
        })
        setTimeout(onSkipAll, 80)
      }}
    />
    <SkipButton
      onClick={() => {
        formikProps.setFieldValue(field, '')
        setTimeout(onSkip, 80)
      }}
    />
  </div>
)

export default RegisterSurveySkipButtons
