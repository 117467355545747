import React from 'react'

import Button from 'common/components/Button'

export interface ConsentBannerProps {
  onAgree: () => void
  isLoading?: boolean
}

const ConsentBanner = ({ onAgree, isLoading = false }: ConsentBannerProps) => {
  return (
    <div className="w-full flex justify-center border-b border-neutral-75 bg-white fixed bottom-0 z-10 shadow-lg">
      <div className="px-4 py-4 flex items-center max-w-7xl">
        <div className="mr-4">
          We have updated our privacy policy. We now use cookies to collect information about how you interact with our
          app. We use this information in order to improve and customize your browsing experience and for analytics and
          metrics about our visitors both on this website and other media. You can find out more in our
          <a href="https://gokickflip.com/privacy-policy" target="_blank" className="text-secondary-teal-dark ml-1">
            <span id="privacyPolicyLink">privacy policy</span>
          </a>
          .
        </div>
        <Button variant="primary" isLoading={isLoading} onClick={onAgree}>
          Agree
        </Button>
      </div>
    </div>
  )
}

export default ConsentBanner
