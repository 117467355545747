import React from 'react'

import Button from 'common/components/Button'

export interface SkipAllButtonProps {
  onClick: () => void
}

const SkipAllButton = ({ onClick }: SkipAllButtonProps) => (
  <Button type="button" variant="text" className="ml-auto text-neutral-400" small onClick={onClick} tabIndex={2}>
    Skip all
  </Button>
)

export default SkipAllButton
