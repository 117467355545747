import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'
import { ConsentStatus } from './../types/consent'

export type PrivacyPolicyConsentResponse = { status: ConsentStatus }

const useContractService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchPrivacyPolicyConsent: createQuery([tenant, 'privacy-policy-consent'], async () => {
      const { data } = await apiClient.get<PrivacyPolicyConsentResponse>(
        `/brands/${tenant}/contracts/privacy-policy/consent`
      )
      return data
    }),
    consentToPrivacyPolicy: async () => {
      const { data } = await apiClient.post<PrivacyPolicyConsentResponse>(
        `/brands/${tenant}/contracts/privacy-policy/consent/agree`
      )
      return data
    },
  }
}

export default useContractService
