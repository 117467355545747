import 'utils/yupNumberSchema'

import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { httpBatchLink } from '@trpc/client'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import LoggedInRoutes from 'cms/auth/components/LoggedInRoutes'
import Login from 'cms/auth/components/Login'
import BrandRoutes from 'cms/layout/BrandRoutes'
import ShopifyScopesManager from 'cms/onlineStores/components/shopify/ShopifyScopesManager'
import RegisterSurvey from 'cms/surveys/components/registerSurvey/RegisterSurvey'
import Tracker from 'cms/tracking/components/Tracker'
import { trpc } from 'common/hooks/trpc'
import TenantContext from 'common/tenant/TenantContext'
import withFlag from 'common/users/components/withFlag'
import Callback from 'public/callback/Callback'
import getAPIUrl from 'utils/getAPIUrl'
import getXMCZRHeader from 'utils/getXMCZRHeader'

import store from './store'

import 'styles/app.css'

const RegisterSurveyWithFlag = withFlag({
  Component: RegisterSurvey,
  feature: 'registration_survey',
})

const tenant = location.hostname.split('.')[0]

window.baseURL = '/admin'
window.USER_INFOS_URL = `/brands/${tenant}/users/infos`
window.UPDATE_TOKEN_URL = `/brands/${tenant}/users/update-token`
window.LOGIN_URL = `/brands/${tenant}/users/login`
window.FORGOT_PASSWORD_URL = `/brands/${tenant}/users/login/forgot`
window.CHANGE_PASSWORD_URL = `/brands/${tenant}/users/login/password-reset`
window.ACCEPT_INVITE_URL = `/brands/${tenant}/users/accept-invite`
window.DOCUMENT_DOMAIN = window.location.host.includes(DOMAIN) ? DOMAIN : ALTERNATE_DOMAIN

if (CURRENT_ENV === 'dev') {
  window.DOCUMENT_DOMAIN = `${window.DOCUMENT_DOMAIN}:${window.location.port}`
}

class App {
  init() {
    // eslint-disable-next-line no-restricted-syntax
    console.log(
      `%c·kıckflıp 2.0 🛹`,
      'color:#FFFFFF; background:#0021f5; font-size:1.5rem; padding:0.5rem; margin: 1rem auto; border: 2px solid #0021f5; border-radius: 8px;font-weight: bold; text-shadow: 1px 1px 1px #000000;'
    )

    const root = createRoot(document.getElementById('root')!)
    const queryClient = new QueryClient()
    const stripe = loadStripe(STRIPE_KEY)

    const trpcClient = trpc.createClient({
      links: [
        httpBatchLink({
          url: `${getAPIUrl()}/trpc`,
          headers: () => ({ ...getXMCZRHeader(), 'x-tenant-id': tenant }),
        }),
      ],
    })

    root.render(
      <FlagsmithProvider options={{ environmentID: FLAGSMITH_KEY, identity: tenant }} flagsmith={flagsmith}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <StripeElementsProvider stripe={stripe}>
              <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <TenantContext.Provider value={tenant}>
                  <>
                    <Toaster position="top-center" containerStyle={{ inset: '56px' }} />

                    <BrowserRouter basename="/">
                      <QueryParamProvider adapter={ReactRouter5Adapter}>
                        <Switch>
                          <Route path="/callback" component={Callback} />
                        </Switch>
                      </QueryParamProvider>
                    </BrowserRouter>

                    <BrowserRouter basename="/admin">
                      <QueryParamProvider adapter={ReactRouter5Adapter}>
                        <Tracker>
                          <Switch>
                            <Route path="/login" component={Login} />
                            <LoggedInRoutes>
                              <>
                                <ShopifyScopesManager />
                                <RegisterSurveyWithFlag />
                                <BrandRoutes />
                              </>
                            </LoggedInRoutes>
                          </Switch>
                        </Tracker>
                      </QueryParamProvider>
                    </BrowserRouter>
                  </>
                </TenantContext.Provider>
              </trpc.Provider>
            </StripeElementsProvider>
          </Provider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelProps={{ className: 'print:!hidden' }}
            toggleButtonProps={{ className: 'print:!hidden' }}
          />
        </QueryClientProvider>
      </FlagsmithProvider>
    )
  }
}

const app = new App()

app.init()

export default app
