import React from 'react'

import Button from 'common/components/Button'

export interface SkipButtonProps {
  onClick: () => void
}

const SkipButton = ({ onClick }: SkipButtonProps) => (
  <Button type="button" variant="text" className="ml-auto text-neutral-400" small onClick={onClick} tabIndex={2}>
    Skip
  </Button>
)

export default SkipButton
