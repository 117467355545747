const initialize = (gtmId: string) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const gtmScript = document.createElement('script')
  gtmScript.async = true
  gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
  document.getElementsByTagName('script')[0]!.parentNode!.prepend(gtmScript)

  const iframe = document.createElement('iframe')
  iframe.setAttribute('width', '0')
  iframe.setAttribute('height', '0')
  iframe.style.display = 'none'
  iframe.style.visibility = 'hidden'
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`
  const noScript = document.createElement('noscript')
  noScript.appendChild(iframe)
  document.body.insertBefore(noScript, document.body.childNodes[0])
}

export default { initialize }
