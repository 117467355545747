import { animated } from '@react-spring/web'
import React from 'react'

import Button from 'common/components/Button'
import InputField from 'common/components/inputs/InputField'
import * as Header from './../../fullPageSurvey/Header'
import RadioGroup from './../../fullPageSurvey/RadioGroup'
import PreviousButton from './../../fullPageSurvey/PreviousButton'
import RegisterSurveySkipButtons from '../RegisterSurveySkipButton'
import { StepProps, teamSizeQuestion } from '../types'

const TeamSize = ({ style, onNext, goToLast, onPrevious, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>
        <PreviousButton onClick={onPrevious} />
        Woah!
        <RegisterSurveySkipButtons field="teamSize" formikProps={formikProps} onSkip={onNext} onSkipAll={goToLast} />
      </Header.H2>
      <Header.H1>{teamSizeQuestion}</Header.H1>
      <div>
        <InputField className="mb-6">
          <RadioGroup
            name="teamSize"
            value={formikProps.values.teamSize}
            onChange={value => formikProps.setFieldValue('teamSize', value)}
            options={[
              {
                label: "It's only me",
                value: "It's only me",
              },
              {
                label: '2-5',
                value: '2-5',
              },
              {
                label: '6-20',
                value: '6-20',
              },
              {
                label: '21-50',
                value: '21-50',
              },
              {
                label: '51+',
                value: '51+',
              },
            ]}
          />
        </InputField>
      </div>
      <div className="flex space-x-2 pt-4">
        <Button type="button" variant="primary" onClick={onNext} className="grow">
          Next
        </Button>
      </div>
    </animated.div>
  )
}

export default TeamSize
