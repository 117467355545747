import React from 'react'

const Callback = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const accessToken = urlParams.get('access_token')
  const redirectUrl = urlParams.get('redirect_url')

  if (accessToken && redirectUrl) {
    window.localStorage.setItem('bearer', accessToken)
    window.location.replace(redirectUrl)
  } else {
    window.location.replace('/')
  }

  return <></>
}

export default Callback
